import { legacyTheme, ThemeProvider as EmotionThemeProvider } from '@prose-ui/legacy';

/**
 * @deprecated: Please use breakpoints from @prose-ui/theme instead.
 */
export const { values: breakpoints } = legacyTheme.breakpoints;

const widths = {
  narrow: 327,
  half: 500,
  normal: 624,
  wide: 950,
  veryWide: 1200,
} as const;

/**
 * @deprecated: Please use spacing from @prose-ui/theme instead.
 */
const spacings = {
  s8: 8,
  s12: 12,
  s16: 16,
  s24: 24,
  s32: 32,
  s36: 36,
  s40: 40,
  s48: 48,
  s56: 56,
  s64: 64,
  s72: 72,
  s80: 80,
  s96: 96,
  s144: 144,
  s272: 272,
} as const;

// other constants
const bannerHeight = 40;
const navbarHeight = 55;
const buttonHeight = 53;
export const headerHeight = bannerHeight + navbarHeight;

export const fullHeight = () => typeof window !== 'undefined' && window.innerHeight - headerHeight;
const fullHeightWithFixedCTA = () =>
  typeof window !== 'undefined' && window.innerHeight - headerHeight - buttonHeight;

/**
 * @deprecated: Please use @prose-ui/theme instead.
 */
export const theme = {
  breakpoints: legacyTheme.breakpoints,
  utils: legacyTheme.utils,
  palette: legacyTheme.palette,
  props: {
    minimumBlockPadding: spacings.s24,
    verticalSpacingMob: spacings.s72,
    verticalSpacingDes: spacings.s144,
    buttonHeight,
    bannerHeight,
    navbarHeight,
    headerHeight,
    fullHeight: fullHeight(),
    fullHeightWithFixedCTA: fullHeightWithFixedCTA(),
    checkboxSize: { small: 16, medium: 28, large: 40 },
  },
  width: widths,
  spacing: spacings,
  typography: legacyTheme.typography,
  zIndex: legacyTheme.zIndex,
  shadows: legacyTheme.shadows,
} as const;

export type OldTheme = typeof theme;

const ThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
);

export default ThemeProvider;
